import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { web3 } from "../../store/web3";
import React, { Component } from 'react';
import { Button, Input, MenuItem } from '@material-ui/core';
import { validate } from "wallet-address-validator";
import { DonactionsAbi } from "../../store/contract";
import { setLoader, getDonations } from "../../store/actions/Auth.js";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../components/common/InputField";
import infocircle from "../../assets/img/infocircle.svg";

import './index.css';

class UpdateDonationsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressToken: "",
      donationTypes: [],
      donationsData: [],
      isPercentage: true,

      accountAddress:"",
      donationEndRange:"",
      donationStartRange:"",
      forDonation:"",
      tokenId:"",
    };
  };

  async componentDidMount() {
    this.getContract();
  }

  async componentWillReceiveProps({ deployedDonations }) {
    if(deployedDonations){
      let {types,donationsData} = await this.dTypes(deployedDonations);
      if(types.length > 0 && donationsData.length > 0) {
          this.setState({
            donationTypes: types, 
            donationsData,
            accountAddress:donationsData[0]['accountAddress'],
            donationEndRange:donationsData[0]['donationEndRange'],
            donationStartRange:donationsData[0]['donationStartRange'],
            forDonation:donationsData[0]['forDonation'],
            tokenId:donationsData[0]['tokenId'],
          })
      }
    }
  }

  dTypes = (deployedDonations) => {
    return new Promise(async (resolve, reject) => {
      console.log("******************** deployedDonations",deployedDonations);
      console.log("******************** DonactionsAbi",DonactionsAbi);
      let donationContract = new web3.eth.Contract(DonactionsAbi, deployedDonations);
      let types = await donationContract.methods.getDonationTypes().call();
      console.log("******************** types",types);
      if(types && types.length >0){
        let donationsData = []
        for (let index = 0; index < types.length; index++) {
          const element = types[index];
          let donations = await donationContract.methods.donationsData(element).call();
          console.log("******************* donations 001",donations);
          donationsData.push(donations);
        }
        if(donationsData.length > 0) return resolve({types,donationsData});
      }
    })
  }

  async getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    if(walletAddress) this.props.getDonations({ walletAddress });
  }

  render() {
    let { addressToken, donationTypes, startAmount, endAmount, type, address, isPercentage, donationsData, accountAddress, donationEndRange,
      donationStartRange,forDonation,tokenId} = this.state;

    console.log("********************* donationsData",donationsData);

    return (
      <div className="content">
        <div className="main-container mint-new">
          <div className="mint-new-NFT">
            <div className="edit-add ">
              <div className="edit-add-title col-12">
                <h1 className="text-dark headTop mt-3">Donation Details</h1>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitDetails}>
                  <div className="wrap">
                        <InputField
                          type="text"
                          name="startAmount"
                          value={donationStartRange}
                          label='START RANGE'
                          disabled
                        />
                     
                    {/* <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Starting Range Here</p>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-3 wrap">
                        <InputField
                          type="text"
                          name="endAmount"
                          value={donationEndRange}
                          label='END RANGE'
                          disabled
                        />
                    {/* <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Ending Range Here</p>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-3 wrap">
                    {donationTypes.length > 0 &&
                      <InputField
                        select={true}
                        type='text'
                        name='type'
                        value={type}
                        onChange={this.handleEditChange}
                        label='TYPE'
                        required={true}
                      >
                          {
                            donationTypes.map(item =>
                              <MenuItem value={`${item}`}>{item}</MenuItem>
                            )
                          }
                      </InputField>
                    }
                    {/* <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Select Your Donation Type</p>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-3 wrap">
                    <InputField
                      type="text"
                      name="address"
                      value={accountAddress}
                      label='BENEFICIARY ADDRESS'
                      disabled
                    />
                    {/* <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Donation Beneficiary Address Here</p>
                      </div>
                    </div> */}
                  </div>
                  <div className="mt-3 wrap">
                    <InputField
                      type="text"
                      name="tokenId"
                      value={tokenId}
                      label='TOKEN ID'
                      disabled
                    />
                    {/* <div className="info-wrap">
                      <img src={infocircle} alt="" />

                      <div className="info">
                        <p>Enter Your Donation Proof NFT Token Id Here</p>
                      </div>
                    </div> */}
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
  getDonations
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations } = Auth;
  return { deployedDonations };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateDonationsDetail);