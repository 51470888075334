/* -- set app title --*/
const AppTitle = 'User Admin Donations Invoicing';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['production'];

/* -- set API URLs --*/
const testing = 'https://www.servergroot.grootdex.io';
const production = 'https://server.cifiapp.com';
// const production = 'https://server.cifiapp.com';
const development = 'https://www.servergroot.grootdex.io';


let SocketUrl;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '', CIFIToken = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 51;
    SocketUrl = development;
    CIFIToken = '0x3CB8fb59815A8a69c197fd46b112Dd70EBF0CDEa';
    explorer = 'https://apothem.blocksscan.io'
    message = 'Please switch your network to XDC Testnet';
    break;
  case 'production':
    networkId = 50;
    SocketUrl = production;
    CIFIToken = '0xe5F9AE9D32D93d3934007568B30B7A7cA489C486';
    message = 'Please switch your network to XDC Mainnet';
    explorer = 'https://xdc.blocksscan.io'
    break;
  case 'testing':
    networkId = 4;
    SocketUrl = testing;
    CIFIToken = '0x3CB8fb59815A8a69c197fd46b112Dd70EBF0CDEa';
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    break;
  default:
    networkId = 51;
    SocketUrl = 'http://localhost:4000';
    CIFIToken = '0x3CB8fb59815A8a69c197fd46b112Dd70EBF0CDEa';
    message = 'Please switch your network to XDC Testnet';
    explorer = 'https://apothem.blocksscan.io'
}
let ApiUrl = `${SocketUrl}/api/v4`;
export { AppTitle, ApiUrl, SocketUrl, networkId, message, explorer, CIFIToken, env };