import EventBus from "eventing-bus";
import { connect } from "react-redux";
import { web3 } from "../../store/web3.js";
import React, { Component, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import { Button } from "@material-ui/core";
import { validate } from "wallet-address-validator";
import { DonactionsAbi,ERC1155Abi } from "../../store/contract/index.js";
import { setLoader, getDonations } from "../../store/actions/Auth.js";
import { ValidatorForm } from "react-material-ui-form-validator";
import InputField from "../../components/common/InputField.js";
import angleleft from "../../assets/img/angle-left.png";
import angleright from "../../assets/img/angle-right.png";

import "./index.css";
import { parse } from "date-fns/esm";

const DonationsDetailSteps = (props) => {

  const totalSteps = 4;
  let [step, setStep] = useState(1);
  let [formValues1, setFormValues1] = useState([{ startAmount: "", endAmount: "" }]);
  let [formValues3, setFormValues3] = useState([""]);
  let [formValues4, setFormValues4] = useState([""]);
  let [formValues5, setFormValues5] = useState([""]);
  let [addressArray, setaddressArray] = useState([]);
  let [amountStartArray, setamountStartArray] = useState([]);
  let [amountEndArray, setamountEndArray] = useState([]);
  let [typeArray, settypeArray] = useState([]);
  let [tokenIdArray, settokenIdArray] = useState([]);
  let [isPercentage, setisPercentage] = useState(true);
  let [donationTypes, setdonationTypes] = useState([]);

  useEffect(() => {
    getContract();
  }, []);

  useEffect(() => {
    if (props.deployedDonations.length > 0) {
      setContract();
    }
  }, [props.deployedDonations]);

  async function dTypes(deployedDonations) {
    return new Promise(async (resolve, reject) => {
      let donationContract = new web3.eth.Contract(
        DonactionsAbi,
        deployedDonations
      );
      let types = await donationContract.methods.getDonationTypes().call();
      resolve(types);
    });
  };

  async function getContract() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    props.getDonations({ walletAddress });
  }

  async function setContract() {
    let result = await dTypes(props.deployedDonations);
    setdonationTypes(result);
  }

  async function submitTransfer() {
    try {

      let { deployedDonations } = props;

      let isEmptyAmountStartArray = amountStartArray.includes("");
      let isEmptyAmountEndArray = amountEndArray.includes("");
      let isEmptyTokenIdArray = tokenIdArray.includes("");
      let isEmptyAddressArray = addressArray.includes("");
      let isEmptyTypeArray = typeArray.includes("");

      if (isEmptyAmountStartArray) {
        EventBus.publish("error", `Start range for a donation is missing!`);
        return;
      }

      if (isEmptyAmountEndArray) {
        EventBus.publish("error", `End range for a donation is missing!`);
        return;
      }

      if (isEmptyTokenIdArray) {
        EventBus.publish("error", `Proof of donation NFT ID for a donation is missing!`);
        return;
      }

      if (isEmptyAddressArray) {
        EventBus.publish("error", `Beneficiary address for a donation is missing!`);
        return;
      }

      if (isEmptyTypeArray) {
        EventBus.publish("error", `Cause name for a donation is missing!`);
        return;
      }

      console.log("****** amountStartArray", amountStartArray);
      console.log("****** amountEndArray", amountEndArray);

      let publicAddress = (await web3.currentProvider.enable())[0];

      let existingTypes = typeArray.filter((element) =>
        donationTypes.includes(element)
      );
      console.log("****** existingTypes", existingTypes);

      if (deployedDonations.length == 0) {
        EventBus.publish("error", `Please deploy a donation contract first!`);
        return;
      }

      if (addressArray.length == 0) {
        EventBus.publish("error", `Please enter the Benefeciary Addresses`);
        return;
      }

      if (amountStartArray.length == 0) {
        EventBus.publish("error", `Please enter the Donation Start Range`);
        return;
      }

      if (amountEndArray.length == 0) {
        EventBus.publish("error", `Please enter the Donation End Range`);
        return;
      }

      if (typeArray.length == 0) {
        EventBus.publish("error", `Please enter the Donation Types`);
        return;
      }

      if (existingTypes.length > 0) {
        EventBus.publish("error", `This donation type is already set`);
        return;
      }

      if (tokenIdArray.length == 0) {
        EventBus.publish("error", `Please enter the Token Ids`);
        return;
      }

      if (tokenIdArray.length !== amountStartArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (tokenIdArray.length !== amountEndArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (tokenIdArray.length !== typeArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (tokenIdArray.length !== addressArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (typeArray.length !== amountStartArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (typeArray.length !== amountEndArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (typeArray.length !== addressArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (addressArray.length !== amountStartArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      if (addressArray.length !== amountEndArray.length) {
        EventBus.publish(
          "error",
          `Instance Quantity should be equal for each field!`
        );
        return;
      }

      // Check 1: The same index integer value of amountEndArray should be greater than the same index integer value of array amountStartArray
      let check1 = amountStartArray.every((value, index) => amountEndArray[index] > value);
      if (check1 == false) {
        EventBus.publish("error", `End range of donation percentage must be greater than start range of donation`);
        return;
      }

      // // Check 2: The integer value of index + 1 for amountStartArray should be greater than the integer value of index for amountEndArray
      // let check2 = amountStartArray.every((value, index) => {
      //   if (index === 0) {
      //     // Skip the first element of array a
      //     return true;
      //   }
      //   return value > amountEndArray[index - 1];
      // });
      // if (check2 == false) {
      //   EventBus.publish("error", `Start range of donation percentage must be greater than end range of previous donation`);
      //   return;
      // }

      // Check 2: The integer value of index + 1 for amountStartArray should be equal to the integer value of last index for amountEndArray
      let check2 = amountStartArray.every((value, index) => {
        if (index === 0) {
          // Skip the first element of array a
          return true;
        }
        return value == amountEndArray[index - 1];
      });

      if (check2 == false) {
        EventBus.publish("error", `Start range of donation percentage must be equal to the end range of previous donation`);
        return;
      }

      console.log("Check 1:", check1);
      console.log("Check 2:", check2);

      for (let i = 0; i < addressArray.length; i++) {
        if (!validate(addressArray[i], "ETH")) {
          EventBus.publish("error", `No. ${i + 1} is not a valid address`);
          return;
        }
      }

      let donationContract = new web3.eth.Contract(
        DonactionsAbi,
        deployedDonations
      );


      console.log("******************* amountStartArray",amountStartArray)
      console.log("******************* amountEndArray",amountEndArray)
      console.log("******************* typeArray",typeArray)
      console.log("******************* tokenIdArray",tokenIdArray)
      console.log("******************* addressArray",addressArray)
      console.log("******************* erc1155",props.erc1155)

    for (let index = 0; index < addressArray.length; index++) {
      const element = addressArray[index];
      let code1 = await web3.eth.getCode(element.toLowerCase());
      if (code1 !== "0x") {
        EventBus.publish("error", `Benefeciary address cannot be a contract!`);
        return;
      }
    }

    let getTokensId = new web3.eth.Contract(
      ERC1155Abi,
      props.erc1155
    );
    let totalIds = await getTokensId.methods.ids().call();
    
    for (let index = 0; index < tokenIdArray.length; index++) {
      const element = tokenIdArray[index];
      if(parseInt(element) > parseInt(totalIds)){
        return EventBus.publish("error", `Max token id can be set to ${totalIds}`);
      }
    }

          /** Approval **/
          props.setLoader({
            message: "Details Submission in Progress...",
            status: true,
          });
          
    

      // Submit transaction to the blockchain and wait for it to be mined
      await web3.eth
        .sendTransaction({
          from: publicAddress,
          value: 0,
          to: deployedDonations,
          gas: 2000000,
          data: donationContract.methods
            .defineDonations(
              amountStartArray,
              amountEndArray,
              typeArray,
              addressArray,
              tokenIdArray,
              true
            )
            .encodeABI(),
        })
        .on("transactionHash", (hash) => console.log(`*******hash = `, hash))
        .on("receipt", async (receipt) => {
          setStep(1);
          setFormValues1([{ startAmount: "", endAmount: "" }]);
          setFormValues3([""]);
          setFormValues4([""]);
          setFormValues5([""]);
          setaddressArray([]);
          setamountStartArray([]);
          setamountEndArray([]);
          settypeArray([]);
          settokenIdArray([]);
          setisPercentage(true);
          setdonationTypes([]);
          props.setLoader({ status: false });
          EventBus.publish("success", `Donation Details Added Successfully!`);
        });
    } catch (e) {
      console.log("********Error = ", e);
      props.setLoader({
        message: "Transfer Not Completed...",
        status: false,
      });
      EventBus.publish("error", `Unable To Execute Tx`);
    }
  };

  async function handleNextStep() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    props.getDonations({ walletAddress });
    setStep(step + 1);
  };

  async function handlePreviousStep() {
    let walletAddress = (await web3.currentProvider.enable())[0];
    props.getDonations({ walletAddress });
    setStep(step - 1);
  };

  async function addFormFields1() {
    setFormValues1([...formValues1, { startAmount: "", endAmount: "" }]);
  }

  async function handleChange1(i, e) {
    formValues1[i][e.target.name] = e.target.value;
    setFormValues1([...formValues1]);
    let array = [];
    for (let items of formValues1) {
      array.push(parseInt(items['startAmount']));
    }
    setamountStartArray([...array]);
  }

  async function handleChange2(i, e) {
    formValues1[i][e.target.name] = e.target.value;
    setFormValues1([...formValues1]);
    let array = [];
    for (let items of formValues1) {
      array.push(parseInt(items['endAmount']));
    }
    setamountEndArray([...array]);
  }

  async function addFormFields2() {
    setFormValues3([...formValues3, ""]);
    settypeArray([...formValues3, ""]);
  }

  async function handleChange3(i, e) {
    formValues3[i] = e.target.value;
    setFormValues3([...formValues3]);
    settypeArray([...formValues3]);
  }

  async function addFormFields3() {
    setFormValues4([...formValues4, ""]);
    setaddressArray([...formValues4, ""]);
  }

  async function handleChange4(i, e) {
    formValues4[i] = e.target.value;
   
    setFormValues4([...formValues4]);
    setaddressArray([...formValues4]);
  }

  async function addFormFields4() {
    setFormValues5([...formValues5, ""]);
    settokenIdArray([...formValues5, ""]);
  }

  async function handleChange5(i, e) {
    formValues5[i] = parseInt(e.target.value);
    setFormValues5([...formValues5]);
    settokenIdArray([...formValues5]);
  }

  const progress = Math.round((step / totalSteps) * 100);

  console.log("*** addressArray :: ", addressArray);
  console.log("*** amountStartArray :: ", amountStartArray);
  console.log("*** amountEndArray :: ", amountEndArray);
  console.log("*** typeArray :: ", typeArray);
  console.log("*** tokenIdArray :: ", tokenIdArray);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="step">
            <h2>Set start & end range</h2>
            <p>Enter Starting & Ending Ranges For Each Donation Type Here</p>

            <div className="form-wrap">
              {formValues1.map((element, index) => (
                <div className="wrap flex">
                  <div className="form-group">
                    <input type="text" name="startAmount" value={element.startAmount || ""} placeholder="Start Range" maxlength="3" required={true} onChange={e => handleChange1(index, e)} />
                  </div>

                  <div className="form-group">
                    <input type="text" name="endAmount" value={element.endAmount || ""} placeholder="End Range" maxlength="3" required={true} onChange={e => handleChange2(index, e)} />
                  </div>
                </div>
              ))}

              <button
                className="add-multiple"
                onClick={(event) => {
                  event.preventDefault();
                  addFormFields1();
                }}
              >
                Add Multiple
              </button>
            </div>

            <div className="btn-wrap">
              <Link className="btn back" to="/NewDonationsDetail">
                <img src={angleleft} alt="" /> Back
              </Link>

              <button className="btn next" onClick={handleNextStep}>
                Next <img src={angleright} alt="" />
              </button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="step">
            <h2>Select donation type</h2>
            <p>Enter Your Donations Cause Here</p>

            <div className="form-wrap">
              <div className="wrap">
                {formValues3.map((element, index) => (
                  <div className="form-group">
                    <input type="text" name={element || ""} value={element || ""} placeholder="Donation Type" maxlength="30" required={true} onChange={e => handleChange3(index, e)} />
                  </div>
                ))}
              </div>

              <button
                className="add-multiple"
                onClick={(event) => {
                  event.preventDefault();
                  addFormFields2();
                }}
              >
                Add Multiple
              </button>
            </div>

            <div className="btn-wrap">
              <button className="btn back" onClick={handlePreviousStep}>
                <img src={angleleft} alt="" /> Back
              </button>

              <button className="btn next" onClick={handleNextStep}>
                Next <img src={angleright} alt="" />
              </button>

            </div>
          </div>
        );
      case 3:
        return (
          <div className="step">
            <h2>Add Beneficiary Address</h2>
            <p>Enter Your Donations Benefeciary Addresses Here.</p>

            <div className="form-wrap">
              <div className="wrap">
                {formValues4.map((element, index) => (
                  <div className="form-group">
                    <input type="text" name={element || ""} value={element || ""} placeholder="Beneficiary Address" maxlength="43" required={true} onChange={e => handleChange4(index, e)} />
                  </div>
                ))}
              </div>

              <button
                className="add-multiple"
                onClick={(event) => {
                  event.preventDefault();
                  addFormFields3();
                }}
              >
                Add Multiple
              </button>
            </div>

            <div className="btn-wrap">
              <button className="btn back" onClick={handlePreviousStep}>
                <img src={angleleft} alt="" /> Back
              </button>

              <button className="btn next" onClick={handleNextStep}>
                Next <img src={angleright} alt="" />
              </button>

            </div>

          </div>
        );

      case 4:
        return (
          <div className="step">
            <h2>Add Number of Beneficiaries</h2>
            <p>Add Number of Beneficiaries in Digits</p>

            <div className="form-wrap">
              <div className="wrap">
                {formValues5.map((element, index) => (
                  <div className="form-group">
                    <input type="text" name={element || ""} value={element || ""} placeholder="Beneficiary Number (For example: 1)" maxlength="5" required={true} onChange={e => handleChange5(index, e)} />
                  </div>
                ))}
              </div>

              <button
                className="add-multiple"
                onClick={(event) => {
                  event.preventDefault();
                  addFormFields4();
                }}
              >
                Add Multiple
              </button>
            </div>

            <div className="btn-wrap">
              <button className="btn back" onClick={handlePreviousStep}>
                <img src={angleleft} alt="" /> Back
              </button>

              <button
                className="btn next"
                onClick={() => submitTransfer()}
              >
                Submit
                <img src={angleright} alt="" />
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content">
      <div className="main-container mint-new">
        <div className="steps-form-wrapper">
          <div className="progress-wrap">
            <div className="progress-head">
              <h3>Add Donation details</h3>

              <span>
                Step {step} of {totalSteps}
              </span>
            </div>

            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
          {renderStep()}
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  setLoader,
  getDonations,
};

const mapStateToProps = ({ Auth }) => {
  let { deployedDonations,erc1155 } = Auth;
  return { deployedDonations,erc1155 };
};
export default connect(mapStateToProps, mapDispatchToProps)(DonationsDetailSteps);
