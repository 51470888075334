import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from "react";
import * as ethUtil from 'ethereumjs-util';
import Button from '@material-ui/core/Button';
import { ValidatorForm } from 'react-material-ui-form-validator';

import { web3 } from "../../store/web3";
import logo from '../../assets/img/cifilogo.png';
import XDC from '../../assets/img/xdc.svg';
import { login, getKycStatus, getKynStatus } from "../../store/actions/Auth";
import { networkId, message } from "../../store/config";
import { Approve, Token } from '../../store/contract/index';


import './index.css';
import { Fragment } from 'react';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      netId: '',
      address: '',
    };
  };

  async componentDidMount() {
    web3.eth.net.getId((err, netId) => {
      this.setState({ netId });
    });
    this.checkAddresses();
  }

  checkAddresses = async () => {
    if (typeof window.ethereum === 'undefined') {
      EventBus.publish('error', `Please Install Metamask!!!`);
      return;
    }

    web3.eth.net.getId(async (err, netId) => {
      if (netId !== 50) {
        const isNetworkAdded = await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0x32', // Chain ID of XDC Testnet
              chainName: 'XDC Network',
              rpcUrls: ['https://erpc.xinfin.network', 'https://earpc.xinfin.network'],
              nativeCurrency: {
                name: 'XDC',
                symbol: 'XDC',
                decimals: 18,
              },
              blockExplorerUrls: ['https://xdcscan.io'],
            },
          ],
        });

        // Auto-connect if the network is added
        if (isNetworkAdded) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x32' }],
          });
        }
      }
      let x = await web3.currentProvider.chainId;
      if (!x) {
        EventBus.publish('error', `Please Install Metamask!!!`);
        return;
      }
    });

    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
    this.props.getKynStatus({ publicAddress: address });
    this.props.getKycStatus({ publicAddress: address });
  };

  handleLogin = async () => {
    let { kycStatus, kynStatus } = this.props;

    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };

    let address = (await web3.currentProvider.enable())[0];
    if (!address) return EventBus.publish("error", "Wallet Address Not Found");

    const nonce = Math.floor(Math.random() * 10000000000);
    const signature = await web3.eth.personal.sign(web3.utils.utf8ToHex(`User Admin Cifi Give ${nonce}`), address)

    const msg = new Buffer(`User Admin Cifi Give ${nonce}`);
    const msgBuffer = ethUtil.toBuffer(msg);
    const msgHash = ethUtil.hashPersonalMessage(msgBuffer);
    const signatureBuffer = ethUtil.toBuffer(signature);
    const signatureParams = ethUtil.fromRpcSig(signatureBuffer);
    const publicKey = ethUtil.ecrecover(
      msgHash,
      signatureParams.v,
      signatureParams.r,
      signatureParams.s
    );
    const addressBuffer = ethUtil.publicToAddress(publicKey);
    const adminAddress = ethUtil.bufferToHex(addressBuffer);
    let whitelist = await (Approve.methods.isWhitelist(adminAddress).call());
    if (whitelist == true) {
      this.props.login(address);
      if (kynStatus == true) {
        setTimeout(() => this.props.history.push('/home'), 1000);
      } else if (kycStatus == true) {
        setTimeout(() => this.props.history.push('/home'), 1000);
      } else {
        EventBus.publish("error", "KYN/KYC still pending!");
      }
    }
    else EventBus.publish("error", "Please login through whitelisted address");

    // if (address) {
    //   this.props.login(address);
    //   setTimeout(() => this.props.history.push('/home'), 1000);
    // }
    // else EventBus.publish("error", "Please login through super admin address");
  };

  render() {
    let { netId } = this.state;
    let { kycStatus, kynStatus } = this.props;

    console.log("*** kycStatus :: ", kycStatus);
    console.log("*** kynStatus :: ", kynStatus);

    return (
      <div className="login-page">
        <div className="container login-area">
          <div className="login-form">
            <h4 className="login-title"><img src={logo} alt="logo" className='logoLogin' />Cifi Give</h4>
            <h1>Welcome Founders</h1>
            <p className='text-white'>To enter the platform and manage your causes and donations, please login using your Metamask wallet.</p>
            {(netId != networkId)
              ? <div className="login-text pt-4"><p>{message}</p></div>
              : <Fragment>
                <ValidatorForm className="validator-form " onSubmit={this.handleLogin}>
                  <Button type="Submit" variant="contained" className='text-white login-btn'>
                    Login with your wallet
                    {/* <img src={XDC} alt="xdc" className='xdc' /> */}
                  </Button>
                </ValidatorForm>
              </Fragment>
            }
          </div>
        </div>
        <div className='bgLoginPg'></div>
      </div >
    );
  }
}

const mapDispatchToProps = {
  login,
  getKycStatus,
  getKynStatus
};

const mapStateToProps = ({ Auth }) => {
  let { kycStatus, kynStatus } = Auth
  return { kycStatus, kynStatus }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);